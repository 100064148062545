import { Accessor, JSX } from "solid-js";
import { design } from "../../lib/useDesign";

export type ButtonProps = JSX.IntrinsicElements["button"] & {
    color?: string;
    variant?: "solid" | "outlined" | "link";
};

export function Button({
    children,
    color,
    variant = "solid",
    ...props
}: ButtonProps) {
    return (
        <button
            {...(props ?? {})}
            classList={{
                ...(props.classList ?? {}),
                [variant]: true,
            }}
            style={{
                "--color": color ?? "white",
            }}
            use:design={[
                {
                    text: "Color",
                    position: "left",
                    props: {
                        type: "select",
                        name: "color",
                        defaultValue: "#fff",
                        data: {
                            White: "#fff",
                            Red: "#F44336",
                            Orange: "#FF9800",
                            Green: "#4CAF50",
                        },
                    },
                },
                {
                    text: "Variant",
                    position: "top",
                    props: {
                        type: "select",
                        name: "variant",
                        defaultValue: "solid",
                        data: {
                            Solid: "solid",
                            Outlined: "outlined",
                            Link: "link",
                        },
                    },
                },
            ]}
        >
            <span
                use:design={{
                    text: "Text",
                    position: "bottom",
                }}
            >
                {children ?? "Button"}
            </span>
        </button>
    );
}

Button.displayName = "Button";
