import { createSignal } from "solid-js";
import { Dynamic } from "solid-js/web";
import { menu } from "../lib/default-elements";
import { DesignerContext } from "../lib/designer-context";
import { AcceptedElement, componentsList } from "./menu";
import { ComponentWrapper } from "./wrapper";

export function Designer() {
    const [component, setComponent] = createSignal<AcceptedElement>(
        componentsList()[0]
    );

    return (
        <DesignerContext.Provider
            value={{
                component,
                setComponent: (component) => setComponent(() => component),
            }}
        >
            <div class="designer">
                <nav>
                    <Dynamic component={menu} />
                </nav>
                <div class="container">
                    <ComponentWrapper component={component} />
                </div>
            </div>
        </DesignerContext.Provider>
    );
}
