import { JSX } from "solid-js";
import { design } from "../../lib/useDesign";

export type LayoutProps = JSX.IntrinsicElements["div"] & {};

export function Layout({ children, ...props }: LayoutProps) {
    return (
        <div class="demo-layout">
            <section class="window-bar">
                <span
                    use:design={{
                        text: "Windows actions",
                        position: "top",
                    }}
                >
                    <div class="close"></div>
                    <div class="resize"></div>
                    <div class="minimize"></div>
                </span>
                <h3>Totally not Visual Studio Code</h3>
            </section>
            <nav
                use:design={{
                    text: "Navigation",
                    position: "left",
                }}
            ></nav>
            <main>Fake content</main>
        </div>
    );
}

Layout.displayName = "Layout";
