import { Accessor, createContext } from "solid-js";
import { AcceptedElement } from "../components/menu";

interface DesignerContextData {
    component: Accessor<AcceptedElement>;
    setComponent(element: AcceptedElement): void;
}

export const DesignerContext = createContext<DesignerContextData>({
    component: null,
    setComponent: () => {},
});
