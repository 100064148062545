import { Accessor, createRenderEffect } from "solid-js";
import { MarkerData } from "../components/marker";

export function design(
    el: HTMLElement,
    data: Accessor<MarkerData | MarkerData[]>
) {
    createRenderEffect(() =>
        el.setAttribute("data-design", JSON.stringify(data()))
    );
}

declare module "solid-js" {
    namespace JSX {
        interface Directives {
            design: MarkerData | MarkerData[];
        }
    }
}
