import { For } from "solid-js";
import { DesignerInputProps } from "../../lib/designer-props";

export function select({
    data,
    value,
    onPropsChanged,
}: DesignerInputProps<"select">) {
    return (
        <select
            class="props"
            onInput={(evt) => {
                onPropsChanged(data[evt.currentTarget.value] as unknown);
            }}
        >
            <For each={Object.entries(data)}>
                {([text, optionValue]) => (
                    <option value={text} selected={value === optionValue}>
                        {text}
                    </option>
                )}
            </For>
        </select>
    );
}
