import {
    Accessor,
    createSignal,
    For,
    onCleanup,
    onMount,
    untrack,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { DynamicWithProps } from "./dynamic-with-props";
import { Marker, MarkerData, MarkerDataInternal } from "./marker";
import { AcceptedElement } from "./menu";

interface ComponentWrapperProps {
    component?: Accessor<AcceptedElement>;
}

export function ComponentWrapper({ component }: ComponentWrapperProps) {
    let componentElement;
    let [markers, setMarkers] = createSignal<MarkerDataInternal[]>([]);
    let [markersCache, setMarkersCache] = createSignal<
        Record<string, MarkerDataInternal[]>
    >({});
    let [props, setProps] = createSignal<object>({});

    const refreshCallback = () => {
        const divWrapper = componentElement as HTMLDivElement;
        const cache = untrack(() => markersCache());
        const newCache = {};
        const newMarkers = Array.from(
            divWrapper.querySelectorAll("[data-design]")
        )
            .map((element: HTMLElement) => {
                try {
                    const jsonData = element.getAttribute("data-design");
                    const cached = cache[jsonData] ?? [];
                    const cachedData = cached.filter(
                        (markerData) => markerData.element === element
                    );

                    if (cachedData.length > 0) {
                        newCache[jsonData] = [
                            ...(newCache[jsonData] ?? []),
                            ...cachedData,
                        ];
                        return cachedData;
                    }

                    const data = JSON.parse(jsonData) as
                        | MarkerData
                        | MarkerData[];

                    if (Array.isArray(data)) {
                        const markerList = data.map((markerData) => ({
                            ...markerData,
                            element,
                            wrapper: divWrapper,
                        }));

                        newCache[jsonData] = [
                            ...(newCache[jsonData] ?? []),
                            ...cachedData,
                        ];

                        return markerList;
                    }

                    if (typeof data === "object") {
                        const markerData = {
                            ...data,
                            element,
                            wrapper: divWrapper,
                        };

                        newCache[jsonData] = [
                            ...(newCache[jsonData] ?? []),
                            markerData,
                        ];

                        return [markerData];
                    }

                    return [];
                } catch (error) {
                    console.error(error);
                    return [];
                }
            })
            .flat();

        setMarkers(newMarkers as MarkerDataInternal[]);
        setMarkersCache(newCache);
    };
    const observer = new MutationObserver(refreshCallback);

    onMount(() => {
        refreshCallback();

        if (componentElement) {
            observer.observe(componentElement, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        }
    });

    onCleanup(() => {
        observer.disconnect();
    });

    return (
        <div class="component-wrapper">
            <div class="markers">
                <For each={markers()}>
                    {(data) => (
                        <Marker
                            setProps={(newProps) => {
                                setProps({ ...props(), ...newProps });
                                console.log({ ...props(), ...newProps });
                            }}
                            componentProps={props}
                            {...data}
                        />
                    )}
                </For>
            </div>
            <div ref={componentElement} class="wrapper-padding">
                <DynamicWithProps
                    component={component() as any}
                    props={props()}
                />
            </div>
        </div>
    );
}
