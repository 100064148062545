import {
    Accessor,
    Component,
    createMemo,
    JSX,
    splitProps,
    untrack,
} from "solid-js";

type DynamicProps<T> = {
    props: T;
    component?: Component<T>;
};

/**
 * renders an arbitrary custom or native component and passes the other props
 * ```typescript
 * <Dynamic component={multiline() ? 'textarea' : 'input'} value={value()} />
 * ```
 * @description https://www.solidjs.com/docs/latest/api#%3Cdynamic%3E
 */
export function DynamicWithProps<T>(
    props: DynamicProps<T>
): Accessor<JSX.Element> {
    const [p] = splitProps(props, ["component"]);
    const [newProps] = splitProps(props, ["props"]);
    const cached = createMemo(() => p.component);
    const cachedProps = createMemo(() => newProps.props);

    return createMemo(() => {
        const component = cached() as Function;
        const componentProps = cachedProps();

        return untrack(() => component(componentProps));
    });
}
