import { Component, createSignal, For, JSX, useContext } from "solid-js";
import { DesignerContext } from "../lib/designer-context";
import * as example from "./example";

export type AcceptedElement =
    | Component<any>
    | string
    | keyof JSX.IntrinsicElements;

const [componentsList, setComponentsList] = createSignal<AcceptedElement[]>(
    Object.values(example)
);

export { componentsList, setComponentsList };

export function Menu() {
    const { component, setComponent } = useContext(DesignerContext);

    return (
        <nav class="default-menu">
            <h3>Components</h3>
            <For each={componentsList()} fallback={<div>Loading...</div>}>
                {(item) => (
                    <div
                        classList={{
                            "menu-item": true,
                            active: component() === item,
                        }}
                        onClick={() => {
                            setComponent(item);
                        }}
                    >
                        {typeof item === "string"
                            ? item
                            : (item as any).displayName ?? item.name}
                    </div>
                )}
            </For>
        </nav>
    );
}
