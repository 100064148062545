import { createSignal } from "solid-js";
import {
    AcceptedElement,
    componentsList,
    Menu as MenuComponent,
    setComponentsList,
} from "../components/menu";

const [defaultComponent, setDefaultComponent] =
    createSignal<AcceptedElement | null>(null);
const [menu, setMenu] = createSignal(MenuComponent);

export {
    defaultComponent,
    setDefaultComponent,
    menu,
    setMenu,
    componentsList,
    setComponentsList,
};
