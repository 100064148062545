import { createMemo, createSignal, onCleanup, onMount, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import { DesignerProps } from "../lib/designer-props";
import * as inputs from "./inputs";

type MarkerComponentProps<T extends keyof DesignerProps> = {
    type: T;
    name: string;
    defaultValue?: unknown;
} & DesignerProps[T];
export interface MarkerData {
    position?: "left" | "top" | "bottom" | "right";
    text?: string;
    props?: MarkerComponentProps<keyof DesignerProps>;
}

export type MarkerDataInternal = MarkerData & {
    element: HTMLElement;
    wrapper: HTMLElement;
    componentProps: Accessor<object>;
    setProps?(newProps: object): void;
};

export function Marker({
    text,
    position,
    element,
    wrapper,
    props,
    componentProps,
    setProps,
}: MarkerDataInternal) {
    const [refreshCounter, setRefreshCounter] = createSignal(0);
    const refresh = () => {
        setRefreshCounter(refreshCounter() + 1);
    };
    const offset = createMemo(() => {
        let rectElement = element.getBoundingClientRect();
        let rectWrapper = wrapper.getBoundingClientRect();

        // Mark this as depend of refreshCounter
        refreshCounter();

        return {
            top: rectElement.top - rectWrapper.top,
            left: rectElement.left - rectWrapper.left,
            bottom: rectWrapper.bottom - rectElement.bottom,
            right: rectWrapper.right - rectElement.right,
            height: rectElement.height,
            width: rectElement.width,
        };
    });

    if ("ResizeObserver" in window) {
        const observer = new ResizeObserver(refresh);

        onMount(() => {
            observer.observe(element);
        });

        onCleanup(() => {
            observer.disconnect();
        });
    }

    return (
        <div>
            <div
                class="marker"
                classList={{
                    top: position === "top",
                    left: position === "left",
                    bottom: position === "bottom",
                    right: position === "right",
                }}
                style={{
                    left: ["top", "bottom"].includes(position)
                        ? `${offset().left}px`
                        : undefined,
                    right: ["top", "bottom"].includes(position)
                        ? `${offset().right}px`
                        : undefined,
                    top: ["left", "right"].includes(position)
                        ? `${offset().top}px`
                        : undefined,
                    bottom: ["left", "right"].includes(position)
                        ? `${offset().bottom}px`
                        : undefined,
                }}
            >
                {text ?? <em>No text specified</em>}
                <Dynamic
                    component={inputs[props?.type]}
                    {...props}
                    value={
                        componentProps()[props.name] ??
                        props.defaultValue ??
                        null
                    }
                    onPropsChanged={(newProps: unknown) => {
                        setProps({ [props.name]: newProps });
                    }}
                />
            </div>
            <svg
                class="marker-path"
                classList={{
                    top: position === "top",
                    left: position === "left",
                    bottom: position === "bottom",
                    right: position === "right",
                }}
                style={{
                    left: ["top", "bottom"].includes(position)
                        ? `${offset().left}px`
                        : undefined,
                    right: ["top", "bottom"].includes(position)
                        ? `${offset().right}px`
                        : undefined,
                    top: ["left", "right"].includes(position)
                        ? `${offset().top}px`
                        : undefined,
                    bottom: ["left", "right"].includes(position)
                        ? `${offset().bottom}px`
                        : undefined,
                    height: ["left", "right"].includes(position)
                        ? `${offset().height}px`
                        : undefined,
                    width: ["top", "bottom"].includes(position)
                        ? `${offset().width}px`
                        : undefined,
                }}
                viewBox={`0 0 ${
                    ["left", "right"].includes(position) ? 160 : offset().width
                } ${
                    ["left", "right"].includes(position) ? offset().height : 80
                }`}
            >
                <Show when={position === "left"}>
                    <path
                        d={`M 8 ${offset().height / 2} H 150 V 0 h 5 M 150 ${
                            offset().height / 2
                        } V ${offset().height} h 5`}
                        stroke="#ccc"
                    />
                </Show>
                <Show when={position === "bottom"}>
                    <path
                        d={`M ${offset().width / 2} 72 V 10 H 0 v -5 M ${
                            offset().width / 2
                        } 10 H ${offset().width} v -5`}
                        stroke="#ccc"
                    />
                </Show>
                <Show when={position === "top"}>
                    <path
                        d={`M ${offset().width / 2} 10 V 70 H 0 v 5 M ${
                            offset().width / 2
                        } 70 H ${offset().width} v 5`}
                        stroke="#ccc"
                    />
                </Show>
            </svg>
        </div>
    );
}
